exports.onRouteUpdate = () => {
  const body = document.querySelector('body');

  body.classList.remove('no-scroll');
  setTimeout(() => {
    body.scrollTo(0, 0);
  }, 10);
};

exports.onClientEntry = async () => {
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`);
  }
};
